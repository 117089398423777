<template>
  <v-dialog
    v-model="dialogAvaliacoes"
    @click:outside="$emit('update:dialogAvaliacoes', false)"
    @keydown.esc="$emit('update:dialogAvaliacoes', false)"
    max-width="80vw"
    max-height="80vh"
  >
    <v-card>
      <v-card-title>
        <span class="text-h6">Avaliações da palestra</span>
      </v-card-title>
      <v-card-text style="min-height: 70vh">
        <div class="d-flex filter">
          <label class="mr-4">Filtre as avaliações pela nota: </label>
          <v-select v-model="filter" :items="itemsFilter" clearable>
            <template v-slot:item="{ item }">
              <div class="mr-2">
                <v-icon
                  v-for="i in item.value"
                  :key="`optionFull${i}`"
                  size="15"
                >
                  mdi-star
                </v-icon>
                <v-icon
                  v-for="i in 5 - item.value"
                  :key="`optionEmpty${i}`"
                  size="15"
                >
                  mdi-star-outline
                </v-icon>
              </div>
              ({{ avaliacoes.filter((i) => i.nota === item.value).length }})
            </template>

            <template v-slot:selection="{ item }">
              <v-icon
                v-for="i in item.value"
                :key="`selectedFull${i}`"
                size="15"
              >
                mdi-star
              </v-icon>
              <v-icon
                v-for="i in 5 - item.value"
                :key="`selectedEmpty${i}`"
                size="15"
              >
                mdi-star-outline
              </v-icon>
            </template>
          </v-select>
          <v-spacer />
        </div>
        <p class="text-caption">
          Exibindo {{ avaliacoesFiltradas.length }} resultado{{
            avaliacoesFiltradas.length !== 1 ? "s" : ""
          }}:
        </p>
        <v-row justify="space-between">
          <v-card
            outlined
            v-for="avaliacao in avaliacoesFiltradas"
            :key="`avaliacao${avaliacao.id}`"
            class="pa-2 d-flex flex-row ma-2 flex-grow-1 card"
          >
            <v-avatar height="50" width="50" class="mr-2">
              <v-img
                v-if="!avaliacao.usuario?.avatar"
                src="../../../assets/avatar_placeholder.png"
              />
              <v-img v-else :src="avaliacao.usuario?.avatar" />
            </v-avatar>
            <div class="d-flex flex-column flex-grow-1">
              <div class="d-flex flex-row mb-2">
                <div class="d-flex flex-column flex-grow-1">
                  <a class="text-subtitle-2">{{ avaliacao.usuario?.nome }}</a>
                  <a class="text-caption">
                    {{ avaliacao.created_at | dateFormat("dd/MM/yyyy") }}
                  </a>
                </div>
                <v-icon
                  v-if="avaliacao.obs.length > 75"
                  @click="avaliacao.isOpen = !avaliacao.isOpen"
                >
                  {{ avaliacao.isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                </v-icon>
              </div>

              <div class="d-flex flex-row">
                <v-icon
                  v-for="item in avaliacao.nota"
                  :key="`labelFull${item}`"
                  size="15"
                >
                  mdi-star
                </v-icon>
                <v-icon
                  v-for="item in 5 - avaliacao.nota"
                  :key="`labelEmpty${item}`"
                  size="15"
                >
                  mdi-star-outline
                </v-icon>
              </div>

              <a class="text-body-2 mt-2">{{
                avaliacao.obs.length > 75 && !avaliacao.isOpen
                  ? avaliacao.obs.substring(0, 72) + "..."
                  : avaliacao.obs
              }}</a>
            </div>
          </v-card>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-4">
        <v-spacer></v-spacer>
        <v-btn
          color="save"
          @click="$emit('update:dialogAvaliacoes', false)"
          class="white--text"
        >
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DialogAvaliacoes",

  props: {
    dialogAvaliacoes: {
      type: Boolean,
      default: false,
    },
    palestra_avaliacoes: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      avaliacoes: [],
      avaliacoesFiltradas: [],
      filter: null,
      itemsFilter: [],
    };
  },

  watch: {
    dialogAvaliacoes(val) {
      this.avaliacoes = [];
      this.avaliacoesFiltradas = [];
      this.filter = null;
      this.itemsFilter = [];
      if (val) this.getAvaliacoes();
    },

    filter(val) {
      if (val)
        this.avaliacoesFiltradas = this.avaliacoes.filter(
          (item) => item.nota === val
        );
      else this.avaliacoesFiltradas = this.avaliacoes;
    },
  },

  methods: {
    getAvaliacoes() {
      this.avaliacoes = this.palestra_avaliacoes.map((item) => {
        return {
          ...item,
          isOpen: false,
        };
      });
      this.avaliacoesFiltradas = this.avaliacoes;

      for (let index = 1; index < 6; index++) {
        this.itemsFilter.push({
          text: index,
          value: index,
          disabled:
            this.palestra_avaliacoes.filter((item) => item.nota === index)
              .length === 0,
        });
      }
    },
  },
};
</script>

<style scoped>
.filter {
  flex-direction: column;
}
@media (min-width: 768px) {
  .card {
    max-width: 47%;
  }

  .filter {
    flex-direction: row;
    align-items: center;
  }
}
</style>
