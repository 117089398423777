<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="accent text-h5 font-weight-bold pa-3">
        <v-icon color="primary" left>mdi-star</v-icon>
        Avaliações de Palestras
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-card flat>
          <v-card-title>
            <v-text-field
              style="max-width: 400px"
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              clearable
              dense
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-data-table
              :headers="headers"
              :search="search"
              :items="palestras"
              :loading="loading"
              :items-per-page="10"
              :sort-desc="true"
              class="data-tables data-tables__row-click"
              @click:row="(row) => openDialog(row)"
            >
              <!-- Data -->
              <template v-slot:item.data="{ item }">
                {{ item.data | dateFormat("dd/MM/yyyy") }}
              </template>

              <!-- Data -->
              <template v-slot:item.nota="{ item }">
                {{
                  item.nota > 0
                    ? item.nota.toString().replace(".", ",") + " ★"
                    : "Não há avaliações"
                }}
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>

    <DialogAvaliacoes
      :dialog-avaliacoes.sync="dialogAvaliacoes"
      :palestra_avaliacoes="palestra_avaliacoes"
    />
  </v-container>
</template>

<script>
import { all } from "@/api/admin/palestras";
import DialogAvaliacoes from "./components/DialogAvaliacoes.vue";

export default {
  name: "PalestrasAvaliacoes",
  data() {
    return {
      loading: true,
      palestras: [],
      search: "",
      dialogAvaliacoes: false,
      palestra_avaliacoes: [],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Palestra",
          value: "descricao",
        },
        {
          text: "Palestrante",
          value: "palestrante",
        },
        {
          text: "Data",
          value: "data",
        },
        {
          text: "Avaliação",
          value: "nota",
          align: "center",
        },
      ];
    },
  },
  methods: {
    openDialog(row) {
      this.palestra_avaliacoes = row.avaliacoes;
      this.dialogAvaliacoes = true;
    },
    getPalestras() {
      this.loading = true;
      all()
        .then((response) => {
          this.palestras = response.map((item) => {
            let nota = 0;
            item.avaliacoes.forEach((i) => {
              nota += i.nota;
            });
            if (nota > 0) nota = nota / item.avaliacoes.length;
            nota = Math.round(nota * 100) / 100;
            return {
              ...item,
              nota,
            };
          });
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.getPalestras();
  },
  components: { DialogAvaliacoes },
};
</script>

<style></style>
